<template>
  <template-installation
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6 md:mb-9 space-y-3">
      <div class="flex space-x-2">
        <px-icon :name="'nuvemshop-lettermark-large.svg'"/>
        <px-icon :name="'switch-horizontal.svg'"/>
        <px-icon :name="'thumbnail-parcelex.svg'"/>
      </div>
      <h1 class="font-bold text-xl">Instalação na Nuvemshop</h1>
      <p class="text-gray-500">
        Para continuar, realize a instalação do aplicativo Parcelex na sua loja.
      </p>
    </header>
    <div>
      <ol class="space-y-9 ordered-list">
        <li class="space-y-5">
          <p>
            Ao clicar em ”Continuar instalação”, você será direcionado
            para a loja de aplicativos da Nuvemshop. Em seguida clique
            em ”Instalar aplicativo”.
          </p>
          <asset-v3
            class="border rounded-md"
            style="width: 100%; height: auto"
            :dir="'context'"
            :name="'nuvemshop-tutorial-01.png'"
          />
        </li>
        <li class="space-y-5">
          <p>
            Você será levado até a página de administrador da sua loja
            e deve concordar com as permissões do aplicativo clicando
            em ”Aceitar e começar a usar”.
          </p>
          <asset-v3
            class="border rounded-md"
            style="width: 100%; height: auto"
            :dir="'context'"
            :name="'nuvemshop-tutorial-02.png'"
          />
        </li>
      </ol>
    </div>
    <footer class="flex justify-center mt-8">
      <a class="anchor-button button-primary" href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem/parcelex" target="_blank">
        <span>Continuar instalação</span>
        <px-icon :name="'external-link-white.svg'"/>
      </a>
    </footer>
  </template-installation>
</template>

<script>
import { mapState } from 'vuex';
import AssetV3 from '@/components/common/AssetV3.vue';
import { PxIcon } from '@/components/custom';
import TemplateInstallation from './TemplateInstallation.vue';

export default {
  name: 'NuvemshopInstalation',
  metaInfo: {
    title: 'Instalação na Nuvemshop',
  },
  components: {
    TemplateInstallation,
    AssetV3,
    PxIcon,
  },
  data: () => ({
    currentOnboardingStep: 6,
  }),
  computed: {
    ...mapState('onboarding', ['onboardingSteps', 'ecommercePlatformOptions']),
  },
};
</script>
